export enum RESOURCES {
  AUTHENTICATION_CREW = '/api/v3/auth/crew/',
  AUTHENTICATION_PIN = '/api/v3/auth/pin/',
  AUTHENTICATION_USER = '/api/v3/auth/user/',
  SHIFT_APPLY_TEMPLATE_TASKS = '/api/v3/shiftplanner/shift-apply-template-tasks',
  SHIFT_OFFER_REPLIES = '/api/v3/shiftplanner/shift-offer-replies',
  SHIFTS = '/api/v3/shiftplanner/shifts',
  SHIFT_COPY_TASKS = '/api/v3/shiftplanner/shift-copy-tasks',
  SHIFT_PUBLISH_TASKS = '/api/v3/shiftplanner/shift-publish-tasks',
  SHIFT_TEMPLATE_SHIFTS = '/api/v3/shiftplanner/template-shifts',
  SHIFT_TEMPLATES = '/api/v3/shiftplanner/templates',
  WORKPLACES = '/api/v3/shiftplanner/workplaces',
  TIME_TRACKING_REPORT_TASKS = '/api/v3/timetracking/time-tracking-report-tasks',
  TIME_TRACKING_REPORT_TASK_JOBS = '/api/v3/timetracking/time-tracking-report-task-jobs',
  PRODUCTS = '/api/v3/platform-app/products',
  CHANGELOG = '/api/v3/audit-app/changelog-settings',
  MEMBER_ROLES = '/api/v3/platform-app/member-roles',
  TEAMS = '/api/v3/platform-app/teams',
  USAGE_RIGHTS = '/api/v3/platform-app/usage-rights',
  INTEGRATIONS = '/api/v3/integration/oauth-status',
}
