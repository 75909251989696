import { memoize } from 'redux-memoize';
import {
  loadContext,
  loadCrews,
  loadPermissions,
  setUiStateValue,
  updateCrewMaintenanceStatus,
  v3LoadMemberRoles,
} from '../action-creators';
import { loadUser } from './users-actions';

import { apiV3, clearableMemoizeCache, dispatchAsyncFnStatusActions, statusCodes } from '../lib';
import { disconnectPusher } from '../services/pusher-service';

export const loadCurrentAuthentication = memoize(
  {},
  ({ api }) =>
    (dispatch) =>
      dispatchAsyncFnStatusActions({
        dispatch,
        actionName: 'LOAD_CURRENT_AUTHENTICATIONS',
        fn: async () => {
          const context = await api.getContext();

          const [users, crews] = context.activeUserId
            ? await Promise.all([
                dispatch(loadUser.unmemoized({ api, userId: context.activeUserId })),
                dispatch(loadCrews({ api, source: '1' })),
              ]).catch((e) => {
                if (e.id === statusCodes.CREW_MAINTENANCE) {
                  dispatch(updateCrewMaintenanceStatus(null, true));
                }
              })
            : [null, null];

          if (crews) {
            await Promise.all(crews.map((crew) => dispatch(v3LoadMemberRoles({ apiV3, crewId: crew.id }))));

            await Promise.all(
              crews.map((crew) =>
                dispatch(loadPermissions({ api, crewId: crew.id }))
                  .then(() => {
                    const crewMaintenance = crew.maintenance === 1;
                    dispatch(updateCrewMaintenanceStatus(crew.id, crewMaintenance));
                  })
                  .catch((e) => {
                    if (e.id !== statusCodes.CREW_MAINTENANCE) {
                      throw e;
                    } else {
                      return dispatch(updateCrewMaintenanceStatus(crew.id, true));
                    }
                  })
              )
            );
          }

          const authenticatedCrewId = context.activeCrewId;
          const crewAuthentication = authenticatedCrewId ? { crewId: authenticatedCrewId } : null;

          const userAuthentication = users
            ? {
                userId: users.id,
                membershipIds: {},
              }
            : null;

          const authenticatedAdminId = context.activeAdminId;
          const adminAuthentication = authenticatedAdminId ? { adminId: authenticatedAdminId } : null;

          return userAuthentication || crewAuthentication
            ? {
                crewAuthentication,
                userAuthentication,
                adminAuthentication,
              }
            : null;
        },
      })
);

export const signOutUser =
  ({ api, clearCache = true }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SIGNOUT_USER',
      fn: () => api.deleteUserAuthentication(),
      namespace: { success: clearCache ? null : 'DO_NOT_CLEAR_STATE' },
      afterSuccessFn: () => {
        if (clearCache) {
          clearableMemoizeCache.clear();
        }
        disconnectPusher();
        return dispatch(loadCurrentAuthentication.unmemoized({ api }));
      },
    });

export const makeSignInIdentifierFromUserId = (userId) => `#user_id=${userId}`;

export const signInUser =
  ({ api, identifier, password }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SIGNIN_USER',
      fnParams: { userIdentifier: identifier, password },
      fn: (params) =>
        Promise.resolve()
          .then(() => api.createUserAuthentication(params))
          .then((userClassObject) => {
            if (!!userClassObject.redirectUrl) {
              return window.location.replace(userClassObject.redirectUrl);
            }
            return userClassObject.id;
          })
          .then((id) => {
            dispatch(setUiStateValue({ key: 'unauthenticatedModalOpen', value: false }));
            return id;
          }),
    });

export const signInUserWithPinCode =
  ({ api, pinCode, userId = null }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SIGNIN_USER_WITH_PINCODE',
      fnParams: userId ? { pin: pinCode, userId } : { pin: pinCode },
      fn: (params) =>
        Promise.resolve()
          .then(() => api.createUserPinAuthentication(params))
          .then((userClassObject) => {
            if (!!userClassObject.redirectUrl) {
              return window.location.replace(userClassObject.redirectUrl);
            }
            return userClassObject.id;
          })
          .then((id) => {
            dispatch(setUiStateValue({ key: 'unauthenticatedModalOpen', value: false }));
            return id;
          }),
    });

export const signOutCrew =
  ({ api }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SIGNOUT_CREW',
      fn: () => {
        return api.deleteCrewAuthentication().then(() => {
          clearableMemoizeCache.clear();
          api.resetHeaders();
          return dispatch(loadCurrentAuthentication.unmemoized({ api }));
        });
      },
    });

export const signInCrewAndSignOutUser =
  ({ api, crewId }) =>
  (dispatch) =>
    dispatchAsyncFnStatusActions({
      dispatch,
      actionName: 'SIGN_IN_CREW_AND_SIGN_OUT_USER',
      fnParams: { crewId },
      fn: async (params) => {
        await api.createCrewAuthentication({ crewId: params.crewId });
        await api.deleteUserAuthentication();
        clearableMemoizeCache.clear();
      },
      afterSuccessFn: () => dispatch(loadContext.unmemoized({ api })),
    });
