import produce from 'immer';
import { AnyAction } from 'redux';

import { ACTION_NAMES } from '../../constants';
import { V3Changelog } from '../../types';

type V3ChangelogAction = AnyAction & { crewId: number; result: { content: V3Changelog[] } };

type V3ChangelogSliceInRedux = { [crewId: number]: V3Changelog[] };

const initialState: V3ChangelogSliceInRedux = {};

// Using immer, param-reassign is actually not a problem
/* eslint-disable no-param-reassign */
export const v3ChangelogReducer = (state = initialState, action: V3ChangelogAction): V3ChangelogSliceInRedux =>
  produce(state, (draft: V3ChangelogSliceInRedux) => {
    switch (action.type) {
      case `${ACTION_NAMES.V3_LOAD_CHANGELOG_SETTINGS}_SUCCESS`:
        draft[action.crewId] = action.result.content;
        break;
      case `${ACTION_NAMES.V3_UPDATE_CHANGELOG_SETTINGS}_SUCCESS`:
        draft[action.crewId] = [action.result.resourceAfterWrite];
        break;
      default:
      // nothing to do => immer returns the original object
    }
  });
