export enum ACTION_NAMES {
  DELETE_ALL_WORKING_TIME_MODEL = 'DELETE_ALL_WORKING_TIME_MODEL',
  DELETE_SETTING_VALUES = 'DELETE_SETTING_VALUES',
  LOAD_LIST_FILES = 'LOAD_LIST_FILES',
  LOAD_REFERRAL_CODE = 'LOAD_REFERRAL_CODE',
  LOAD_SETTINGS_DEFINITIONS = 'LOAD_SETTINGS_DEFINITIONS',
  LOAD_SETTINGS_VALUES = 'LOAD_SETTINGS_VALUES',
  LOAD_USER = 'LOAD_USER',
  POST_SETTING_VALUES = 'POST_SETTING_VALUES',
  POST_SPREADSHEET_REQUEST = 'POST_SPREADSHEET_REQUEST',
  SET_PASSWORD_BY_TOKEN = 'SET_PASSWORD_BY_TOKEN',
  SIGNOUT_USER = 'SIGNOUT_USER',
  SIGN_UP_USER = 'SIGN_UP_USER',
  UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE',

  V3_APPLY_SHIFT_TEMPLATE_TASK = 'V3_APPLY_SHIFT_TEMPLATE_TASK',
  V3_COPY_SHIFTS = 'V3_COPY_SHIFTS',
  V3_CREATE_SHIFT_OFFER_REPLY = 'V3_CREATE_SHIFT_OFFER_REPLY',
  V3_CREATE_TEMPLATE = 'V3_CREATE_TEMPLATE',
  V3_CREATE_TEMPLATE_SHIFTS = 'V3_CREATE_TEMPLATE_SHIFTS',
  V3_DELETE_SHIFT_OFFER_REPLY = 'V3_DELETE_SHIFT_OFFER_REPLY',
  V3_DELETE_TEMPLATE = 'V3_DELETE_TEMPLATE',
  V3_DELETE_TEMPLATE_SHIFTS = 'V3_DELETE_TEMPLATE_SHIFTS',
  V3_GET_TEMPLATES = 'V3_GET_TEMPLATES',
  V3_GET_TEMPLATE_SHIFTS = 'V3_GET_TEMPLATE_SHIFTS',
  V3_LOAD_NEXT_SHIFT = 'V3_LOAD_NEXT_SHIFT',
  V3_LOAD_SHIFTS = 'V3_LOAD_SHIFTS',
  V3_LOAD_SHIFT_OFFER_REPLIES = 'V3_LOAD_SHIFT_OFFER_REPLIES',
  V3_LOAD_WORKPLACES = 'V3_LOAD_WORKPLACES',
  V3_LOAD_INTEGRATIONS = 'V3_LOAD_INTEGRATIONS',
  V3_OPTIMISTIC_UPDATE_SHIFT = 'V3_OPTIMISTIC_UPDATE_SHIFT',
  V3_PUBLISH_SHIFTS = 'V3_PUBLISH_SHIFTS',
  V3_SET_PUBLISH_SHIFT = 'V3_SET_PUBLISH_SHIFT',
  V3_SIGN_IN_CREW_AND_SIGN_OUT_USER = 'V3_SIGN_IN_CREW_AND_SIGN_OUT_USER',
  V3_SIGN_IN_USER = 'V3_SIGN_IN_USER',
  V3_SIGN_IN_USER_WITH_PIN = 'V3_SIGN_IN_USER_WITH_PIN',
  V3_SIGN_OUT_CREW = 'V3_SIGN_OUT_CREW',
  V3_SIGN_OUT_USER = 'V3_SIGN_OUT_USER',
  V3_TRANSFORM_SHIFTS_DRAFT = 'V3_TRANSFORM_SHIFTS_DRAFT',
  V3_UPDATE_TEMPLATE = 'V3_UPDATE_TEMPLATE',

  V3_LOAD_PRODUCTS = 'V3_LOAD_PRODUCTS',
  V3_LOAD_CHANGELOG_SETTINGS = 'V3_LOAD_CHANGELOG_SETTINGS',
  V3_UPDATE_CHANGELOG_SETTINGS = 'V3_UPDATE_CHANGELOG_SETTINGS',
  V3_LOAD_TEAMS = 'V3_LOAD_TEAMS',
  V3_LOAD_MEMBER_ROLES = 'V3_LOAD_MEMBER_ROLES',
  V3_CREATE_MEMBER_ROLE = 'V3_CREATE_MEMBER_ROLE',
  V3_UPDATE_MEMBER_ROLE = 'V3_UPDATE_MEMBER_ROLE',
  V3_DELETE_MEMBER_ROLE = 'V3_DELETE_MEMBER_ROLE',
  V3_LOAD_USAGE_RIGHTS = 'V3_LOAD_USAGE_RIGHTS',
}
