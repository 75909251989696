import { ignoreReturnFor } from 'promise-frites';
import { DEFAULT_PAGE_SIZE_FOR_HTTP_CALL } from '../constants';
import { Filter, HTTP_METHOD, PageContext, PatchResource, Resource, RSQL_OPERATOR, ServiceMethods } from '../types';
import { array } from '../utils/';

const { isEmpty } = array;

interface V3ErrorResponse {
  error: string;
  path: string;
  requestId: string;
  status: number;
  timestamp: string;
}

enum SEPARATOR {
  AND_RSQL = ';',
  AND = '&',
}

type TokenResponse = {
  token: string;
};

type PostProcessFunction = ((v3Token?: string) => string | undefined) | (() => void) | undefined;

export default class HttpRequest {
  #url: string;
  #headers: Headers;
  #postProcessFn?: PostProcessFunction;

  constructor(url: string, headers: Headers, postProcessFn?: PostProcessFunction) {
    this.#url = url;
    this.#headers = headers;
    this.#postProcessFn = postProcessFn;
  }

  getMethods() {
    return {
      getOne: (id, async = false) => (async ? this.#getOneAsync(id) : this.#getOne(id)),
      get: (filter, operators, pageContext = { pageSize: DEFAULT_PAGE_SIZE_FOR_HTTP_CALL }, async = false, sortBy) =>
        async ? this.#getAsync(filter, pageContext, sortBy) : this.#get(filter, operators, pageContext, sortBy),
      createOne: (resource, async = false) => (async ? this.#createOneAsync(resource) : this.#createOne(resource)),
      create: (resources, async = false) => (async ? this.#createAsync(resources) : this.#create(resources)),
      patchOne: (id, patchData, async = false) =>
        async ? this.#patchOneAsync(id, patchData) : this.#patchOne(id, patchData),
      patch: (patchData, async = false) => (async ? this.#patchAsync(patchData) : this.#patch(patchData)),
      putOne: (id, resource, async = false) => (async ? this.#putOneAsync(id, resource) : this.#putOne(id, resource)),
      put: (resources, async = false) => (async ? this.#putAsync(resources) : this.#put(resources)),
      deleteOne: (id, async = false) => (async ? this.#deleteOneAsync(id) : this.#deleteOne(id)),
      delete: (ids, async = false) => (async ? this.#deleteAsync(ids) : this.#delete(ids)),
    } as ServiceMethods;
  }

  #getOne(id: number | string) {
    return this.#fetchAndReturnJson({
      url: this.#url + "/" + id,
      method: HTTP_METHOD.GET,
    });
  }

  #get(filter: Filter, operators: Record<string, RSQL_OPERATOR>, pageContext: PageContext, sortBy?: string) {
    return this.#fetchAndReturnJson({
      url: this.#url + this.#appendRequestParams(filter, operators, pageContext, sortBy),
      method: HTTP_METHOD.GET,
    });
  }

  #createOne(resource: Resource) {
    this.#headers.append('Content-Type', 'application/json');
    return this.#fetchAndReturnJson({
      url: this.#url,
      method: HTTP_METHOD.POST,
      body: resource,
    }).then(ignoreReturnFor((result: TokenResponse) => this.#postProcessFn && this.#postProcessFn(result.token)));
  }

  #create(resources: Resource[]) {
    this.#headers.append('Content-Type', 'application/json');

    return this.#fetchAndReturnJson({
      url: this.#url,
      method: HTTP_METHOD.PATCH,
      body: this.#getPatchDataForPostRequest(resources),
    });
  }

  #patchOne(id: number, patchData: Resource) {
    this.#headers.append('Content-Type', 'application/json');

    return this.#fetchAndReturnJson({
      url: this.#url + "/" + id,
      method: HTTP_METHOD.PATCH,
      body: patchData,
    });
  }

  #patch(patchData: PatchResource[]) {
    this.#headers.append('Content-Type', 'application/json');

    return this.#fetchAndReturnJson({
      url: this.#url,
      method: HTTP_METHOD.PATCH,
      body: this.#getPatchDataForPatchRequest(patchData),
    });
  }

  #putOne(id: number, resource: Resource) {
    this.#headers.append('Content-Type', 'application/json');

    return this.#fetchAndReturnJson({
      url: this.#url + "/" + id,
      method: HTTP_METHOD.PUT,
      body: resource,
    });
  }

  #put(resources: Resource[]) {
    this.#headers.append('Content-Type', 'application/json');

    return this.#fetchAndReturnJson({
      url: this.#url,
      method: HTTP_METHOD.PATCH,
      body: this.#getPatchDataForPutRequest(resources),
    });
  }

  #deleteOne(id: number) {
    this.#headers.append('Content-Type', 'application/json');
    return this.#fetchAndReturnJson({
      url: this.#url + "/" + id,
      method: HTTP_METHOD.DELETE,
    }).then(ignoreReturnFor(() => this.#postProcessFn && this.#postProcessFn()));
  }

  #delete(ids: number[]) {
    this.#headers.append('Content-Type', 'application/json');
    return this.#fetchAndReturnJson({
      url: this.#url,
      method: HTTP_METHOD.PATCH,
      body: this.#getPatchDataForDeleteRequest(ids),
    });
  }

  #fetchAndReturnJson({ url, method, body }: { url: string; method: HTTP_METHOD; body?: unknown }) {
    return fetch(url, {
      method,
      headers: this.#headers,
      ...(body ? { body: JSON.stringify(body) } : {}),
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorResponse: V3ErrorResponse = await response.json();
          throw {
            ...errorResponse,
            id: errorResponse.status,
            error: new Error(errorResponse.error),
          };
        }
        return response;
      })
      .then((response) => response.json());
  }

  #getOneAsync(id: number | string) {
    return Promise.reject('Not implemented yet');
  }

  #getAsync(filter: Filter, pageContext: PageContext, sortBy?: string) {
    return Promise.reject('Not implemented yet');
  }

  #createOneAsync(resource: Resource) {
    return Promise.reject('Not implemented yet');
  }

  #createAsync(resources: Resource[]) {
    return Promise.reject('Not implemented yet');
  }

  #patchOneAsync(id: number, patchData: Resource) {
    return Promise.reject('Not implemented yet');
  }

  #patchAsync(patchData: PatchResource[]) {
    return Promise.reject('Not implemented yet');
  }

  #putOneAsync(id: number, resource: Resource) {
    return Promise.reject('Not implemented yet');
  }

  #putAsync(resources: Resource[]) {
    return Promise.reject('Not implemented yet');
  }

  #deleteOneAsync(id: number) {
    return Promise.reject('Not implemented yet');
  }

  #deleteAsync(ids: number[]) {
    return Promise.reject('Not implemented yet');
  }

  #getRsqlFilterString(
    objectToConvert: Filter,
    operators: Record<string, RSQL_OPERATOR>,
    separator: SEPARATOR = SEPARATOR.AND_RSQL
  ) {
    return Object.getOwnPropertyNames(objectToConvert).reduce((acc, property) => {
      return acc + (acc ? separator : '') + `${property}${operators[property]}${objectToConvert[property]}`;
    }, '');
  }

  #getUrlParamsStringWithSeparator(objectToConvert: PageContext, separator: SEPARATOR = SEPARATOR.AND) {
    return Object.getOwnPropertyNames(objectToConvert).reduce((acc, property) => {
      return acc + (acc ? separator : '') + `${property}=${objectToConvert[property]}`;
    }, '');
  }

  #appendRequestParams(
    filter: Filter,
    operators: Record<string, RSQL_OPERATOR>,
    pageContext: PageContext,
    sortBy?: string
  ) {
    const filterString = this.#getRsqlFilterString(filter, operators);
    const pageContextString = this.#getUrlParamsStringWithSeparator(pageContext);

    const paramsArray = [];

    if (filterString) {
      paramsArray.push('filter=' + filterString);
    }
    if (sortBy) {
      paramsArray.push(`sort=${sortBy}`);
    }
    if (pageContextString) {
      paramsArray.push(pageContextString);
    }

    return paramsArray.length ? '?' + paramsArray.join('&') : '';
  }

  #getPatchDataForPostRequest(resources: Resource[]) {
    return resources.map((resource) => ({ method: HTTP_METHOD.POST, body: resource }));
  }

  #getPatchDataForPatchRequest(patchData: PatchResource[]) {
    return patchData.map((patchResource) => ({
      method: patchResource.operation,
      ...(patchResource.operation !== HTTP_METHOD.POST ? { path: `${patchResource.resource.id}` } : {}),
      ...(patchResource.resource && patchResource.operation !== HTTP_METHOD.DELETE
        ? { body: patchResource.resource }
        : {}),
    }));
  }

  #getPatchDataForPutRequest(resources: Resource[]) {
    return resources.map((resource) => ({ method: HTTP_METHOD.PUT, path: `${resource.id}`, body: resource }));
  }

  #getPatchDataForDeleteRequest(ids: number[]) {
    return ids.map((id) => ({ method: HTTP_METHOD.DELETE, path: `${id}` }));
  }
}
